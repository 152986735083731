import * as React from "react"
import { navigate } from "gatsby"
import { useState, useEffect } from "react"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"

import SuccessfulOrder from '../containers/successfulorder';

//import { BLOCKS } from "@contentful/rich-text-types"
//import { renderRichText } from "gatsby-source-contentful/rich-text"

const OrderSuccess = ({ location }) => {

  //const [loading, setLoading] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState(null);
  //let isOrder = true;

  //console.log(location);
  useEffect(() => {
    let isOrder = true;
    if (location.state === null || location.state === undefined) {
      navigate("/go-adfree/");
    } else {
      if (isOrder) {
        setSubscriptionID(location.state.subscriptionID);
        //const subscriptionID = location.state.subscriptionID;
      }
    }
    return () => { isOrder = false };
  }, [location]);


  //console.log(subscriptionID);



  //const subscriptionID = null; //{location.state.subscriptionID};

  //console.log({location.state.subscriptionID});

  /*const data = useStaticQuery(
    /*graphql`
      query {
        contentfulAdfree(contentfulid: {eq: 1}) {
          content {
            raw
          }
        }
      }
    `
  )*/

  //const Text = ({ children }) => <p>{children}</p>

  /*const options = {
      renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      },
  }*/

  return (

    <Layout page="internal">
      <Seo title="Successful Order" />
      <Container className="container">
          {subscriptionID && <SuccessfulOrder subscriptionID={subscriptionID} />}
      </Container>
    </Layout>
  )

}

export default OrderSuccess