import * as React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "../components/container"

import "./successfulorder.css"

const SuccessfulOrder = ({subscriptionID}) => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "success"}) {
                subtitle
                title
                bodyText {
                    raw
                }
            }
          }
        `
    )

    const Text = ({ children }) => <p>{children}</p>

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        },
    }

    return (
        <Container id="success">
            <div className="defaultWrapper">
                <div className="successWrapper">
                    <h1>{data.contentfulSection.title}</h1>
                    <h2>{data.contentfulSection.subtitle}</h2>
                    <h3>For your record, your reference ID is <span className="subscriptionID">{subscriptionID}</span></h3>
                    {renderRichText(data.contentfulSection.bodyText, options)}
                </div>
            </div>
        </Container>
    )
}

export default SuccessfulOrder